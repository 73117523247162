import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from 'src/environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {SignInResponse} from '../../../_internal/sign_in_response';

@Injectable({
    providedIn: 'root'
})
export class LinkAccountResource {
    private readonly url: string = `${environment.apiGatewayUrl}/user-service/auth`;


    constructor(private http: HttpClient) {
    }

    public signIn(email: string, password: string): Observable<SignInResponse> {
        return this.http.post<SignInResponse>(`${this.url}/sign_in`, {email, password});
    }

}

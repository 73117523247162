<h1 class="underlined-heading">We’re better together</h1>
<p>
	Thanks for accepting {{membershipRequestDetails.inviter}}’s invite to
	join {{ membershipRequestDetails.property_name}}!
</p>

<ng-container *ngIf="membershipRequestDetails.user_exists">
	<p>If you want to accept this invitation, please click on the link below. </p>
</ng-container>
<ng-container *ngIf="!membershipRequestDetails.user_exists">
	<p>If you want to accept this invitation, let’s get you set up with an account first.</p>
	<app-user-registration [userDetails]="userDetails"
						   [markAsDirtyEvent]="markAsDirty"
						   (hasErrors)="onFormChange($event)"></app-user-registration>
</ng-container>

<div class="d-flex justify-content-center align-items-center flex-column mt-4">
	<button mat-raised-button
			color="primary"
			(click)="onAccept()">Accept
	</button>
	<div class="alert alert-danger mt-4"
		 *ngIf="hasError">
		Error occurred. Please try again or contact support.
	</div>
</div>


import {ActivatedRoute, Router} from '@angular/router';
import {Component, EventEmitter, OnInit} from '@angular/core';
import {MembershipRequest} from '../../providers/api/dto/membership_request';
import {UserDetails} from '../../_internal/user_details';
import {PropertyMembershipResource} from '../../providers/api/resources/property-membership.resource';
import {BlockUI, NgBlockUI} from 'ng-block-ui';

@Component({
    selector: 'app-property-invitation',
    templateUrl: './property-invitation.component.html',
    styleUrls: ['./property-invitation.component.scss']
})
export class PropertyInvitationComponent implements OnInit {
    @BlockUI() blockUI: NgBlockUI;

    public markAsDirty: EventEmitter<boolean> = new EventEmitter();

    public readonly consumableToken: string;
    public membershipRequestDetails: MembershipRequest;
    public userDetails: UserDetails = {first_name: '', last_name: '', phone: '', password: ''};
    public hasError = false;

    private validationPassed: boolean;
    private readonly invitationType: string = 'property_membership';

    constructor(private activatedRoute: ActivatedRoute,
                private router: Router,
                private membershipResource: PropertyMembershipResource) {
        this.membershipRequestDetails = this.activatedRoute.snapshot.data.requestDetails;
        this.consumableToken = this.activatedRoute.snapshot.paramMap.get('token');
    }

    public onFormChange($event) {
        this.validationPassed = $event;
    }

    public onAccept(): void {
        if (!this.membershipRequestDetails.user_exists) {
            this.markAsDirty.emit(true);
            if (this.validationPassed) {
                this.acceptInvitation();
            }
        } else {
            this.acceptInvitation();
        }
    }

    private acceptInvitation(): void {
        this.blockUI.start('Loading data');
        const userParams = this.membershipRequestDetails.user_exists ? undefined : this.userDetails;

        this.membershipResource.acceptInvitation(this.consumableToken, userParams)
                .subscribe((response) => {
                            this.blockUI.stop();
                            this.router.navigate([`invitation/accepted/${this.invitationType}`]);
                        },
                        () => {
                            this.blockUI.stop();
                            this.hasError = true;
                        }
                );
    }

    ngOnInit() {
    }

}

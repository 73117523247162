import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

@Component({
    selector: 'app-expired',
    templateUrl: './expired.component.html',
    styleUrls: ['./expired.component.scss']
})
export class ExpiredComponent implements OnInit {
    public type: string;

    constructor(private activatedRoute: ActivatedRoute) {
        this.type = this.activatedRoute.snapshot.paramMap.get('type');
    }

    ngOnInit() {
    }

}

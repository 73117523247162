import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-circle-of-trust-invitation',
  templateUrl: './circle-of-trust-invitation.component.html',
  styleUrls: ['./circle-of-trust-invitation.component.scss']
})
export class CircleOfTrustInvitationComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

import {EMPTY, Observable} from 'rxjs';
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import {BlockUI, NgBlockUI} from 'ng-block-ui';
import {Injectable} from '@angular/core';
import {catchError, finalize, map} from 'rxjs/operators';
import {UserService} from './user.service';

@Injectable()
export class CancelTokenResolver implements Resolve<any> {
    @BlockUI() blockUI: NgBlockUI;

    constructor(private userService: UserService,
                private router: Router) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<string> | Promise<any> | string {
        this.blockUI.start('Loading data');
        return this.userService.validateToken(route.params.token).pipe(
                catchError(err => {
                    this.router.navigateByUrl('/expired/delete');
                    return EMPTY;
                }),
                map(response => response),
                finalize(() => this.blockUI.stop())
        );
    }
}

<div class="mt-4 d-none">
    <p> Download the Sonic App </p>
    <div class="row no-gutters">
        <div class="col-lg-2">
            <a href="https://apps.apple.com/us/app/sonic-watergate-leak-stopper/id1456637045" target="_blank"><img class="app-badge" src="assets/img/appStore.svg"/></a>
        </div>
        <div class="col-lg-2">
            <a href="https://play.google.com/store/apps/details?id=com.herolabs.hybridappmobile" target="_blank"><img class="app-badge" src="assets/img/googlePlay.svg"/></a>
        </div>
    </div>
</div>

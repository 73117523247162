<div class="flying-tile"
     [ngClass]="{'focus':isFocused,'phone':inputType==='tel'}">
    <div class="form-group form-group-default mb-2"
         [ngClass]="getControlValidationClass(true)">
        <label [for]="inputId"
               translate>{{label}}</label>
        <span class="color-heading"
              *ngIf="isRequired">*</span>
        <input [type]="isPasswordRevealed ? 'text' : inputType"
               #inputElement
               [tabIndex]="tabIndex"
               *ngIf="inputType !== 'select' && inputType !== 'tel'"
               tappable
               [autofocus]="isAutofocus"
               (focus)="isFocused = true"
               (blur)="isFocused = false"
               [formControl]="control"
               class="form-control"
               [id]="inputId"/>
        <div class="clear-field"
             (mousedown)="clearField();$event.preventDefault()"
             *ngIf="control.value && isFocused && inputType!=='select'"
             tappable>
            <i class="icon icon-close"></i>
        </div>
        <div class="show-hide-password"
             tappable
             (mousedown)="revealPassword();$event.preventDefault()"
             (mouseup)="hidePassword();$event.preventDefault()"
             *ngIf="control.value && isFocused && (isPasswordRevealed || inputType==='password')">
            <i class="icon"
               [ngClass]="isPasswordRevealed?'icon-eye-closed':'icon-eye-open'"></i>
        </div>
        <div *ngIf="inputType==='tel'">
            <ngx-intl-tel-input [cssClass]="'form-control'"
								[preferredCountries]="[countryISO.UnitedKingdom,countryISO.Poland, countryISO.UnitedStates]"
								[enableAutoCountrySelect]="true"
								[enablePlaceholder]="false"
								[searchCountryFlag]="true"
								[selectFirstCountry]="true"
								(change)="restartTelTimer()"
								[tabIndex]="tabIndex"
								[maxLength]="15"
								[phoneValidation]="true"
								name="phone"
								[formControl]="control"></ngx-intl-tel-input>

        </div>
    </div>
</div>
<div class="form-errors"
     *ngIf="invalidMessage">
    <div *ngIf="isControlInvalid() && isString(invalidMessage)">
        <p *ngIf="isControlInvalid()">
            {{invalidMessage}}
        </p>
    </div>
    <div *ngIf="isControlInvalid() && !isString(invalidMessage)">
        <p *ngFor="let item of control.errors | keyvalue">
            {{ invalidMessage[item.key]}}
        </p>
    </div>
</div>
<div class="form-errors">
    <p *ngIf="!isControlInvalid()">&nbsp;</p>
</div>

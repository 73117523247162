import * as _ from 'lodash';
import {Subscription, timer} from 'rxjs';
import {CountryISO} from 'ngx-intl-tel-input';
import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';

@Component({
    selector: 'app-flying-input',
    templateUrl: './flying-input.component.html',
    styleUrls: ['./flying-input.component.scss'],
})
export class FlyingInputComponent implements OnInit, OnChanges {

    public inputId = '';
    public isPasswordRevealed = false;

    @Input() inputType = 'text';
    @Input() isFocused = false;
    @Input() formGroup: FormGroup;
    @Input() control: FormControl;
    @Input() label: string;
    @Input() invalidMessage: string | string[];
    @Input() controlName: string;
    @Input() isRequired = false;
    @Input() isAutofocus = false;
    @Input() alwaysShowErrors = false;
    @Input() availableOptions: string[];
    @Input() selectChange: any;
    @Input() tabIndex: number;
    @Output() selectChanged: EventEmitter<string> = new EventEmitter();
    @ViewChild('inputElement', {static: true}) inputEl: ElementRef;
    private telSub: Subscription;
    private telTimerDelay = 750;
    public countryISO = CountryISO;


    constructor(private cdr: ChangeDetectorRef) {
    }

    ngOnInit() {
        this.inputId = `${this.controlName}Input`;
        this.isRequired = _.get(this.control, 'errors.required', false);
    }

    public ngOnChanges(changes: SimpleChanges): void {
        const oldAutofocus = _.get(changes['isAutofocus'], 'previousValue', false);
        this.isAutofocus = _.get(changes['isAutofocus'], 'currentValue', false);
        if (this.isAutofocus) {
            timer().subscribe(() => {
                this.setFocusOnElement();

            });
        } else if (oldAutofocus) {
            const el = _.get(this.inputEl, 'nativeElement');
            if (el) {
                el.blur();
            }
        }
    }

    public getControlValidationClass(force = false): string {
        if (this.isControlInvalid(force)) {
            return 'invalid';
        }
        if (this.isControlValid()) {
            return 'valid';
        }
        return '';
    }

    public isControlInvalid(force = false): boolean {
        if (this.inputType === 'tel') {
            return (this.alwaysShowErrors || !this.isFocused || force) &&
                    this.control.dirty &&
                    this.control.value !== null &&
                    _.get(this.control, 'errors.validatePhoneNumber');
        } else {
            return (this.alwaysShowErrors || !this.isFocused) &&
                    !this.control.valid && this.control.dirty;
        }
    }

    public isControlValid(): boolean {
        return this.control.valid && this.control.dirty;
    }

    public isString(obj: string | string[]): boolean {
        return typeof obj === 'string' || obj instanceof String;
    }


    public revealPassword(): void {
        this.isPasswordRevealed = true;
    }

    public hidePassword(): void {
        this.isPasswordRevealed = false;
    }

    public emitSelectChanged(): void {
        this.selectChanged.emit(this.control.value);
    }

    public clearField(): void {
        this.control.setValue('');
        this.setFocusOnElement();
        this.cdr.detectChanges();
    }

    private setFocusOnElement(): void {
        if (this.inputType === 'tel') {
            this.isFocused = true;
            this.restartTelTimer();
        }
        const el = _.get(this.inputEl, 'nativeElement');
        if (el) {
            el.focus();
        }
        this.cdr.detectChanges();
    }

    restartTelTimer() {
        this.telSub = timer(this.telTimerDelay)
                .subscribe(() => {
                    this.isFocused = false;
                    this.telSub.unsubscribe();
                });
    }
}

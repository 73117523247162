import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-accepted',
    templateUrl: './accepted.component.html',
    styleUrls: ['./accepted.component.scss']
})
export class AcceptedComponent implements OnInit {

    public readonly type: string;

    constructor(private activatedRoute: ActivatedRoute) {
        this.type = this.activatedRoute.snapshot.paramMap.get('type');
    }

    ngOnInit() {}

}

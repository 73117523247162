<h1 class="underlined-heading">We’re sorry to see you go.</h1>
<ng-container *ngIf="!accountDeleted;else noAccount">
    <p>
        To complete removing account process, please click on the link below.
    </p>
    <div class="d-flex justify-content-center mt-4">
        <button mat-raised-button color="primary" (click)="onAccept()">Remove</button>
    </div>
</ng-container>
<ng-template #noAccount>
    <p>Your request has been completed and your account removed. </p>
</ng-template>
<p class="my-4">
    If you have a moment, we’d be grateful if you could drop a quick message to hi@Hero-Labs.com to let us know why you
    decided to leave. We read all your feedback, and we use it to improve our products and fix things that don’t work.
</p>

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../../../environments/environment';


@Injectable({
    providedIn: 'root'
})
export class UserResource {

    constructor(private http: HttpClient) {
    }

    public validateToken(token: string): Observable<string> {
        const url = `${environment.apiGatewayUrl}/iot-core-public/cancellation`;
        return this.http.get<string>(`${url}/${token}/validate`, {});
    }

    public deleteAccount(token: string): Observable<string> {
        const url = `${environment.apiGatewayUrl}/iot-core-public/cancellation`;
        return this.http.delete<string>(`${url}/${token}/consume`, {});
    }
}

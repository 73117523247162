import { ActivatedRoute, Router } from '@angular/router';
import {
    Component,
    OnInit
} from '@angular/core';
import {RegexUtils} from '../shared/RegexUtils';
import {FormControl, Validators} from '@angular/forms';
import {BlockUI, NgBlockUI} from 'ng-block-ui';
import { LinkAccountResource } from '../../providers/api/resources/link-account.resource';
import { SignInResponse } from '../../_internal/sign_in_response';

@Component({
    selector: 'app-login_auth',
    templateUrl: './login-auth.component.html',
    styleUrls: ['./login-auth.component.scss']
})

export class LoginAuthComponent implements OnInit {
    @BlockUI() blockUI: NgBlockUI;
    public clientId: string;
    public scope: string;
    public state: string;
    public responseType: string;
    public redirectUri: string;

    public email: FormControl = new FormControl('', [Validators.required,
        Validators.pattern(RegexUtils.EMAIL_REGEX)]);
    public password: FormControl = new FormControl('', [Validators.required,
        Validators.pattern(RegexUtils.PASSWORD_REGEX)]);
    public hide = true;
    public hasError = false;

    constructor(private activatedRoute: ActivatedRoute, private router: Router, 
        private linkAccountResource: LinkAccountResource) {

    }

    ngOnInit() {
        this.activatedRoute.queryParams.subscribe(params => {
            this.clientId = params['client_id'];
            this.scope = params['scope'];
            this.state = params['state'];
            this.responseType = params['response_type'];
            this.redirectUri = params['redirect_uri'];
            if (this.clientId === undefined || this.scope === undefined || this.state === undefined || this.responseType === undefined || this.redirectUri === undefined) {
                this.router.navigate([`expired/account_linking_failed`]);
            }
          });
    }

    public onSubmit(): void {
        if (this.password.valid && this.email.valid) {
            this.blockUI.start('Loading data');
            this.linkAccountResource.signIn(this.email.value, this.password.value)
                    .subscribe((response) => {
                                this.blockUI.stop();
                                this.redirect(response.token_details);
                            },
                            () => {
                                this.blockUI.stop();
                                this.hasError = true;
                            }
                    );
        } else {
            this.hasError = true;
        }
    }

    private redirect(token: string): void {
        const url = this.redirectUri + '&state=' + this.state + '&access_token=' + token + '&token_type=Bearer';
        window.location.href = url;
    }

}

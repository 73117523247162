<h1 class="underlined-heading">Forgot your password? </h1>
<p>
	Let’s get you a new one.
</p>
<p class="mb-4">
	Passwords should be at least eight characters long, with at least one letter, one digit and one special character.
</p>

<app-flying-input [control]="password"
				  controlName="password"
				  inputType="password"
				  [isAutofocus]="true"
				  label="Password"
				  invalidMessage="Password must fit requirements"></app-flying-input>

<ul class="mt-2">
	<li [ngClass]="{'valid':checkPartOfPassword(password.value, latinLettersRegexp)}">
		<i class="icon icon-tick"></i>
		Only latin letters
	</li>
	<li [ngClass]="{'valid':checkPartOfPassword(password.value, lengthRegexp)}">
		<i class="icon icon-tick"></i>
		8 characters long
	</li>
	<li [ngClass]="{'valid':checkPartOfPassword(password.value, digitRegexp)}">
		<i class="icon icon-tick"></i>
		One number
	</li>
	<li [ngClass]="{'valid':checkPartOfPassword(password.value, letterRegexp)}">
		<i class="icon icon-tick"></i>
		One letter
	</li>
	<li [ngClass]="{'valid':checkPartOfPassword(password.value, specialRegexp)}">
		<i class="icon icon-tick"></i>
		One special character
	</li>
</ul>

<div class="d-flex justify-content-center align-items-center flex-column mt-4">
	<button mat-raised-button
			color="primary"
			(click)="onSubmit()">Reset password
	</button>
	<div class="alert alert-danger mt-4"
		 *ngIf="hasError">
		Error occurred. Please try again or contact support.
	</div>
</div>

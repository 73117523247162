import {ExpiredComponent} from './consumer-app/components/expired/expired.component';
import {InvitationResolver} from './consumer-app/providers/services/invitation.resolver';
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {PropertyInvitationComponent} from './consumer-app/components/property-invitation/property-invitation.component';
// tslint:disable-next-line:max-line-length
import {CircleOfTrustInvitationComponent} from './consumer-app/components/circle-of-trust-invitation/circle-of-trust-invitation.component';
import {AcceptedComponent} from './consumer-app/components/accepted/accepted.component';
import {PasswordResetComponent} from './consumer-app/components/password-reset/password-reset.component';
import {PasswordResolver} from './consumer-app/providers/services/password.resolver';
import {CancelAccountComponent} from './consumer-app/components/cancel-account/cancel-account.component';
import {CancelTokenResolver} from './consumer-app/providers/services/cancelToken.resolver';
import {LoginAuthComponent} from './consumer-app/components/login-auth/login-auth.component';

const routes: Routes = [
    {
        path: 'invitation/property_membership/:token',
        component: PropertyInvitationComponent,
        resolve: {
            requestDetails: InvitationResolver
        }
    },
    {
        path: 'password_reset/:token',
        component: PasswordResetComponent,
        resolve: {
            userDetails: PasswordResolver
        }
    },
    {
        path: 'cancellation/:token',
        component: CancelAccountComponent,
        resolve: {
            cancellationDetails: CancelTokenResolver
        }
    },
    {
        path: 'invitation/accepted/:type',
        component: AcceptedComponent,
    },
    {
        path: 'password/:type',
        component: AcceptedComponent,
    },
    {
        path: 'expired/:type',
        component: ExpiredComponent,
    },
    {
        path: 'invitation/circle_of_trust/:token',
        component: CircleOfTrustInvitationComponent
    },
    {
        path: 'oauth',
        component: LoginAuthComponent
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}

import { ConsumerSharedModule } from './consumer-app/components/shared/consumer-shared.module';
import { ConsumerProvidersModule } from './consumer-app/providers/consumer-providers.module';
import { ConsumerComponentsModule } from './consumer-app/components/consumer-components.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BlockUIModule } from 'ng-block-ui';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MaterializeModule } from './materialize.module';

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        ConsumerComponentsModule,
        BlockUIModule.forRoot(),
        ConsumerProvidersModule,
        ConsumerSharedModule,
        BrowserAnimationsModule,
        MaterializeModule
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule { }

import {EMPTY, Observable} from 'rxjs';
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import {BlockUI, NgBlockUI} from 'ng-block-ui';
import {Injectable} from '@angular/core';
import {catchError, finalize, map} from 'rxjs/operators';
import {PasswordResource} from '../api/resources/password.resource';
import {User} from '../../_internal/user';

@Injectable()
export class PasswordResolver implements Resolve<any> {
    @BlockUI() blockUI: NgBlockUI;

    constructor(private passwordResource: PasswordResource,
                private router: Router) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<User> | Promise<any> | User {
        this.blockUI.start('Loading data');
        return this.passwordResource.validateToken(route.params.token).pipe(
                catchError(err => {
                    this.router.navigateByUrl('/expired/password');
                    return EMPTY;
                }),
                map(response => {
                    this.blockUI.stop();
                    return response;
                }),
                finalize(() => this.blockUI.stop())
        );
    }
}

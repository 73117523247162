import {PropertyMembershipResource} from './../api/resources/property-membership.resource';
import {BlockUI, NgBlockUI} from 'ng-block-ui';
import {MembershipRequest} from './../api/dto/membership_request';
import {EMPTY, Observable} from 'rxjs';
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import {Injectable} from '@angular/core';
import {catchError, finalize, map} from 'rxjs/operators';

@Injectable()
export class InvitationResolver implements Resolve<any> {
    @BlockUI() blockUI: NgBlockUI;

    constructor(private membershipRequestResource: PropertyMembershipResource,
                private router: Router) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<MembershipRequest> | Promise<any> | MembershipRequest {
        this.blockUI.start('Loading data');
        return this.membershipRequestResource.fetchInvitationDetails(route.params.token).pipe(
                catchError(err => {
                    this.router.navigateByUrl('/expired/invitation');
                    return EMPTY;
                }),
                map(response => {
                    this.blockUI.stop();
                    return response;
                }),
                finalize(() => this.blockUI.stop())
        );
    }
}

import {Component, OnInit} from '@angular/core';
import {UserService} from '../../providers/services/user.service';
import {ActivatedRoute} from '@angular/router';

@Component({
    selector: 'app-cancel-account',
    templateUrl: './cancel-account.component.html',
    styleUrls: ['./cancel-account.component.scss']
})
export class CancelAccountComponent implements OnInit {
    public accountDeleted = false;
    private token: string;

    constructor(private userService: UserService,
                private route: ActivatedRoute) {
    }

    ngOnInit() {
        this.token = this.route.snapshot.paramMap.get('token');
    }

    public onAccept(): void {
        this.userService.deleteAccount(this.token)
            .subscribe((res) => {
                this.accountDeleted = true;
            });
    }
}

import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {UserResource} from '../api/resources/user.resource';

@Injectable()
export class UserService {

    constructor(private userResource: UserResource) {
    }


    public validateToken(token: string): Observable<string> {
        return this.userResource.validateToken(token);
    }

    public deleteAccount(token: string): Observable<string> {
        return this.userResource.deleteAccount(token);
    }
}

import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from 'src/environments/environment';
import {HttpClient} from '@angular/common/http';
import {User} from '../../../_internal/user';

@Injectable({
    providedIn: 'root'
})
export class PasswordResource {
    private readonly url: string = `${environment.apiGatewayUrl}/iot-core-public/passwords`;

    constructor(private http: HttpClient) {
    }

    public validateToken(token: string): Observable<User> {
        return this.http.get<User>(`${this.url}/${token}/validate`, {});
    }

    public consumeToken(token: string, password: string): Observable<string> {
        return this.http.put<string>(`${this.url}/${token}/consume`, {password});
    }
}

import {Component, OnInit} from '@angular/core';
import {PasswordResource} from '../../providers/api/resources/password.resource';
import {User} from '../../_internal/user';
import {FormControl, Validators} from '@angular/forms';
import {BlockUI, NgBlockUI} from 'ng-block-ui';
import {ActivatedRoute, Router} from '@angular/router';
import {RegexUtils} from '../shared/RegexUtils';

@Component({
    selector: 'app-password-reset',
    templateUrl: './password-reset.component.html',
    styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent implements OnInit {
    @BlockUI() blockUI: NgBlockUI;

    public user: User;
    public token: string;
    public hide = true;
    public password: FormControl = new FormControl('', [Validators.required,
        Validators.pattern(RegexUtils.PASSWORD_REGEX)]);

    public letterRegexp = /^(?=.*[a-zA-Z]).+$/;
    public digitRegexp = /^(?=.*\d).+$/;
    public specialRegexp = /^(?=.*[!@#$%^&*,.\-+=\[\]{}()<>_'":;\\|?\/~`]).+$/;
    public lengthRegexp = /^[a-zA-Z0-9!@#$%^&*,.\-+=\[\]{}()<>_'":;\\|?\/~`]{8,}$/;
    public latinLettersRegexp = /^[a-zA-Z0-9!@#:$%^&*,.\-+=\[\]{}()<>_'";\\|?\/~`]+$/;
    public hasError = false;

    constructor(private activatedRoute: ActivatedRoute,
                private router: Router,
                private passwordResource: PasswordResource) {
        this.user = this.activatedRoute.snapshot.data.userDetails;
        this.token = this.activatedRoute.snapshot.paramMap.get('token');
    }

    ngOnInit() {
    }

    public onSubmit(): void {
        if (this.password.valid) {
            this.blockUI.start('Loading data');
            this.passwordResource.consumeToken(this.token, this.password.value)
                    .subscribe((response) => {
                                this.blockUI.stop();
                                this.router.navigate([`password/reset_successful`]);
                            },
                            () => {
                                this.blockUI.stop();
                                this.hasError = true;
                            }
                    );
        } else {
            this.password.markAsDirty();
        }
    }

    public checkPartOfPassword(string: string, regexp: RegExp): boolean {
        return regexp.test(string);
    }
}

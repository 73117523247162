import {UserDetails} from './../../../_internal/user_details';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import * as _ from 'lodash';
import {RegexUtils} from '../RegexUtils';

@Component({
    selector: 'app-user-registration',
    templateUrl: './user-registration.component.html',
    styleUrls: ['./user-registration.component.scss']
})

export class UserRegistrationComponent implements OnInit {
    public userForm: FormGroup;
    public hide = true;

    @Input() userDetails: UserDetails;
    @Input() markAsDirtyEvent: EventEmitter<boolean>;
    @Output() hasErrors: EventEmitter<boolean> = new EventEmitter();

    constructor() {
    }

    ngOnInit() {
        this.userForm = new FormGroup({
            first_name: new FormControl(this.userDetails.first_name, [Validators.required, Validators.minLength(2)]),
            last_name: new FormControl(this.userDetails.last_name, [Validators.required, Validators.minLength(2)]),
            phoneObject: new FormControl(this.userDetails.phone, [Validators.required]),
            password: new FormControl(this.userDetails.password, [Validators.required, Validators.pattern(RegexUtils.PASSWORD_REGEX)]),
        });
        this.onChanges();
        this.markAsDirtyEvent.subscribe((event) => {
            this.markAsDirty();
        });
    }

    public onChanges(): void {
        this.userForm.valueChanges.subscribe((values) => {
            this.userDetails.first_name = values.first_name;
            this.userDetails.last_name = values.last_name;
            this.userDetails.phone = values.phoneObject ? values.phoneObject.e164Number : '';
            this.userDetails.password = values.password;
            this.hasErrors.emit(this.userForm.valid);
        });
    }

    public markAsDirty(): void {
        _.forEach(this.userForm.controls, (control: FormControl) => {
            control.markAsDirty();
        });
    }
}

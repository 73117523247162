import {MaterializeModule} from './../../materialize.module';
import {ConsumerSharedModule} from './shared/consumer-shared.module';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PropertyInvitationComponent} from './property-invitation/property-invitation.component';
import {CircleOfTrustInvitationComponent} from './circle-of-trust-invitation/circle-of-trust-invitation.component';
import {AcceptedComponent} from './accepted/accepted.component';
import {ExpiredComponent} from './expired/expired.component';
import {PasswordResetComponent} from './password-reset/password-reset.component';
import {ReactiveFormsModule} from '@angular/forms';
import {CancelAccountComponent} from './cancel-account/cancel-account.component';
import { LoginAuthComponent } from './login-auth/login-auth.component';

@NgModule({
    declarations: [
        PropertyInvitationComponent,
        CircleOfTrustInvitationComponent,
        AcceptedComponent,
        ExpiredComponent,
        PasswordResetComponent,
        CancelAccountComponent,
        LoginAuthComponent
    ],
    imports: [
        CommonModule,
        ConsumerSharedModule,
        MaterializeModule,
        ReactiveFormsModule
    ]
})
export class ConsumerComponentsModule {
}

<form class="example-form" [formGroup]="userForm">

    <div class="row mt-3">
        <div class="col-lg-6">
            <app-flying-input [control]="userForm.get('first_name')"
                              inputType="text"
                              [isAutofocus]="true"
                              label="First name"
                              invalidMessage="First name is required"></app-flying-input>
        </div>
        <div class="col-lg-6">
            <app-flying-input [control]="userForm.get('last_name')"
                              inputType="text"
                              [isAutofocus]="true"
                              label="Last name"
                              invalidMessage="Last name is required"></app-flying-input>
        </div>
    </div>
    <div class="row mt-2">
        <div class="col-lg-6">
            <app-flying-input [control]="userForm.get('phoneObject')"
                              inputType="tel"
                              controlName="phoneObject"
                              [isAutofocus]="true"
                              label="Mobile Number"
                              invalidMessage="Mobile number is required"></app-flying-input>
        </div>
        <div class="col-lg-6">
            <app-flying-input [control]="userForm.get('password')"
                              inputType="password"
                              [isAutofocus]="true"
                              label="Password"
                              invalidMessage="Password must contains at least one letter, one digit, one special character and be at least 8 characters long"></app-flying-input>
        </div>
    </div>
</form>

import {AcceptInvitationResponse} from './../dto/accept.invitation.response';
import {UserDetails} from './../../../_internal/user_details';
import {MembershipRequest} from './../dto/membership_request';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from 'src/environments/environment';
import {HttpClient} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class PropertyMembershipResource {
    private readonly url: string = `${environment.apiGatewayUrl}/iot-core-public/membership_requests`;

    constructor(private http: HttpClient) {
    }

    public fetchInvitationDetails(token: string): Observable<MembershipRequest> {
        return this.http.get<MembershipRequest>(this.url + `/${token}`, {});
    }

    public acceptInvitation(token: string, userDetails?: UserDetails): Observable<AcceptInvitationResponse> {
        const params = userDetails !== undefined ? {user_params: userDetails} : {};

        return this.http.post<AcceptInvitationResponse>(this.url + `/${token}/accept`, params);
    }
}

<h1 class="underlined-heading">Token expired</h1>
<ng-container *ngIf="type==='invitation'">
	<p>Your token is invalid or has expired. Contact the person who invited you to get another invitation link.</p>
</ng-container>
<ng-container *ngIf="type==='password'">
	<p>Your token is invalid or has expired. Try to submit the password reset request again.</p>
</ng-container>
<ng-container *ngIf="type==='delete'">
	<p>Your token is invalid or has expired. Try to submit the delete account request again.</p>
</ng-container>
<ng-container *ngIf="type==='account_linking_failed'">
	<p>Your request is invalid or has expired. Try to submit the account linking request again.</p>
</ng-container>

<h1 class="underlined-heading">Login</h1>
<p>
	Please enter your account details below to link your account.
</p>

<app-flying-input [control]="email"
				  controlName="email"
				  inputType="email"
				  [isAutofocus]="true"
				  label="Email"
				  invalidMessage="Invalid email"></app-flying-input>

<app-flying-input [control]="password"
				  controlName="password"
				  inputType="password"
				  [isAutofocus]="true"
				  label="Password"
				  invalidMessage="Invalid password"></app-flying-input>

<div class="d-flex justify-content-center align-items-center flex-column mt-4">
	<button mat-raised-button
			color="primary"
			(click)="onSubmit()">Link account
	</button>
	<div class="alert alert-danger mt-4"
		 *ngIf="hasError">
		 	Email or password is incorrect. Please try again or contact support.
	</div>
</div>

<block-ui>
    <div class="container-fluid h-100 px-0 ">
        <app-header></app-header>
        <div class="row h-100 no-gutters">
            <div class="col-lg-6 d-none d-lg-block">
                <div class="main-image"></div>
            </div>
            <div class="col-lg-6 h-100">
                <div class="row h-100 align-items-center no-gutters">
                    <div class="col-11 col-lg-8 mx-auto">
                        <router-outlet></router-outlet>
                    </div>
                </div>
            </div>
        </div>
        <app-footer></app-footer>
    </div>
</block-ui>

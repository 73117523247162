import {AppRoutingModule} from './../../app-routing.module';
import {PropertyMembershipResource} from './api/resources/property-membership.resource';
import {HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {InvitationResolver} from './services/invitation.resolver';
import {PasswordResource} from './api/resources/password.resource';
import {PasswordResolver} from './services/password.resolver';
import {CancelTokenResolver} from './services/cancelToken.resolver';
import {UserService} from './services/user.service';
import {UserResource} from './api/resources/user.resource';
import { LinkAccountResource } from './api/resources/link-account.resource';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        HttpClientModule,
        AppRoutingModule
    ],
    providers: [
        PropertyMembershipResource,
        InvitationResolver,
        PasswordResource,
        PasswordResolver,
        CancelTokenResolver,
        UserService,
        UserResource,
        LinkAccountResource
    ]
})
export class ConsumerProvidersModule {
}

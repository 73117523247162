<ng-container [ngSwitch]="type">
    <div *ngSwitchCase="'property_membership'">
        <h1 class="underlined-heading">Invitation accepted</h1>
        <p> Congratulations! You have successfully accepted your invitation. </p>
        <p> You are now ready to manage recently subscribed property. </p>
    </div>

    <div *ngSwitchCase="'reset_successful'">
        <h1 class="underlined-heading">Success!</h1>
        <p> Password has been reset successfully.</p>
        <p> You are now able to sign in using your new password! </p>
    </div>
</ng-container>
